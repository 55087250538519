import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import ComponentLoading from '../common/ComponentLoading'


export default function Forums() {
    const api = useAxios('protected')
    const [forums, setForums] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getForums()
    }, [])

    const getForums = async () => {
        try {
            setLoading(true)

            const response = await api.get('/forums')

            setForums(response.data)
        } catch (error) {
            toast.error('Failed to get forums: ' + error.message)
        } finally {
            setLoading(false)
        }
    }


    const forumElements = forums.map(forum => (
        <div key={forum.id} style={styles.forum}>
            <p style={styles.name}>{forum.name}</p>

            <p style={styles.description}>{forum.description}</p>

            <Link 
                to={`${forum.id}/problems`}
                state={{forum: forum}}
                style={styles.navigate}
            >
                <p style={styles.count}>Postlarni ko'rish - Jami [{forum.numOfPosts}]</p>
            </Link>
        </div>
    ))


    if (loading) {
        return <ComponentLoading />
    }


    return(
        <div style={styles.container}>
            {forumElements}
        </div>
    )
}


const styles = {
    container: {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        padding: 20
    },
    forum: {
        display: 'flex',
        flexDirection: 'column',
        width: 400, 
        height: 350, 
        backgroundColor: 'rgb(212, 230, 241)', 
        padding: 20, 
        margin: 7, 
        border: '2px solid rgb(41, 128, 185)', 
        borderRadius: 20
    },
    name: {
        fontSize: 30, 
        fontWeight: '700', 
        color: 'rgb(21, 67, 96)', 
        marginBottom: 10,
        userSelect: 'none'
    },
    description: {
        fontSize: 22, 
        fontWeight: '500', 
        color: 'rgb(0, 0, 0)', 
        flexGrow: 1,
        userSelect: 'none'
    },
    navigate: {
        textDecoration: 'none', 
        alignItems: 'center',
        margin: '0 3px',
        padding: '3px 10px',
        borderRadius: 10,
        backgroundColor: 'rgb(235, 222, 240)',
        border: '1px solid rgb(108, 52, 131)'
    },
    count: {
        fontSize: 25, 
        fontWeight: '700', 
        color: 'rgb(180, 84, 20)',
        margin: 5,
        userSelect: 'none'
    }
}