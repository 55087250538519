import React, { useState } from 'react'


export default function Apply({ loading, name, apply, color1 = 'rgb(88, 214, 141)', color2 = 'rgb(130, 224, 170)' }) {
    const [hovered, setHovered] = useState(false)


    if (loading) {
        return (
            <button
                style={{ backgroundColor: 'rgb(225, 226, 229)', ...styles.apply}}
            > {name} </button>
        )
    }


    return(
        <button
            style={{ backgroundColor: hovered ? color1 : color2, ...styles.apply}}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={apply}
        > {name} </button>
    )
}


const styles = {
    apply: {
        height: 28, 
        fontSize: 19,
        fontWeight: '700',
        border: 'none',
        borderRadius: 5,
        marginRight: 20,
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        userSelect: 'none',
    }
}
