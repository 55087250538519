import React from 'react'


export default function Privacy() {
    return (
        <div className='privacy'>
            <div className='privacy-container'>
                <p className='label'>Foydalanish shartlari</p>


                <div className='section-container  section-sep'>
                    <p className='section-label' style={{textAlign: 'center'}}>Ta’limda to‘g‘ri tanlov platformasining foydalanish shartlari</p>
                    <p className='section-label'>(Ommaviy oferta)</p>
                </div>


                <div className='section-container section-sep'>
                    <p className='section-label'>1. Umumiy qoidalar</p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>1.1.</b> <a className='privacy-url' href="https://ttt.org.uz/">ttt.org.uz</a> - professional 
                        mutaxassislar tomonidan ta’lim sohasida dolzarb, ishonchli va muhim ma’lumotlar maqola yoki post 
                        ko‘rinishida e’lon qilib boriladigan tizimi (keyingi o‘rinlarda “tizim” deb yuritiladi) “TTTUTORIALS” 
                        ma’suliyati cheklangan jamiyati (keyingi o‘rinlarda “ijrochi” deb yuritiladi) ga tegishli va u 
                        tomonidan boshqariladi. Ushbu loyiha 2024-yilning iyun oyida ishga tushurilgan bo‘lib, tizim 
                        foydlanuvchilari (keyingi o‘rinlarda “foydalanuvchi” deb yuritiladi) ushbu ommaviy ofertaga 
                        asosan <a className='privacy-url' href="https://ttt.org.uz/">ttt.org.uz</a> tizimidan foydalanadi. 
                        O‘zbekiston Respublikasi Fuqarolik kodeksining 364-moddasiga ko‘ra, mazkur oferta foydalanuvchi 
                        bilan ijrochi o‘rtasidagi qonuniy bitim hisoblanadi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>1.2.</b> Foydalanuvchi <a className='privacy-url' href="https://ttt.org.uz/">ttt.org.uz</a> veb-sayti, 
                        uning mobil ilovasidan foydalanishi yoki ularda ro‘yxatdan o‘tishi uning ushbu oferta bilan 
                        tanishganligi va hech qanday istisnosiz keltirib o‘tilgan barcha shartlarni qabul qilganligini 
                        bildiradi. Agar foydalanuvchi ofertaning ayrim qismlariga rozi bo‘lmasa, tizim imkoniyatlaridan 
                        foydalanish huquqiga ega bo‘lmaydi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>1.3.</b> Foydalanuvchi 1.2-bandda ko‘rsatilgan tarzda shartlarni qabul qilgan paytdan boshlab 
                        ushbu oferta kuchga kirgan hisoblanadi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>1.4.</b> Ijrochi foydalanuvchilarni ogohlantirmagan holda tizimning ish faoliyatini vaqtincha 
                        yoki doimiy to‘xtatish, va/yoki ma’lum foydalanuvchilarga cheklash huquqini o‘zida saqlab qoladi.
                    </p>
                </div>


                <div className='section-container section-sep'>
                    <p className='section-label'>2. Tizimdan foydalanish</p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>2.1.</b> Foydalanuvchi tizimning mobil ilovasi orqali quyidagi sahifalardan foydalanishi mumkin:
                    </p>
                    <p className='section-text'>
                        <b>•</b> <b>“MAQOLALAR”</b> - Fanlar bo‘yicha mutaxassislar tomonidan yozilgan maqolalarni o‘qish, 
                        reaksiya bildirish, saqlash va/yoki ularni ulashish;
                    </p>
                    <p className='section-text'>
                        <b>•</b> <b>“FORUMLAR”</b> - Ma’lum fan sohasiga bag‘ishlangan postlar jamlanmalarini ko‘rish, 
                        forum ichidagi postlarni o‘qish, reaksiya bildirish, saqlash va/yoki ularni ulashish;
                    </p>
                    <p className='section-text'>
                        <b>•</b> <b>“SAQLANGAN”</b> - Yuqorida keltirib o‘tilgan “MAQOLALAR” va “FORUMLAR” sahifalaridagi 
                        mos ravishda saqlangan maqola va post larni o‘qish, reaksiya bildirish, saqlash va/yoki ularni ulashish;
                    </p>
                    <p className='section-text'>
                        <b>•</b> <b>“AKKAUNT”</b> - Foydalanuvchi timizga saqlangan o‘z shaxsiy ma’lumotlarini ko‘rishi, 
                        taxrirlashi, va o‘chirishi, yoki tizimda turli sozlamalarni amalgan oshirish.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>2.2.</b> <a className='privacy-url' href="https://ttt.org.uz/">ttt.org.uz</a> tizimidan voyaga yetgan 
                        foydalanuvchi mustaqil foydalanishi mumkin. Voyaga yetmagan foydalanuvchilar ota-ona yoki vasiy shaxs 
                        ruxsati hamda nazorati ostida foydalanish huquqiga ega.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>2.3.</b> Qulaylik yaratish nuqtai nazaridan tizim doimiy takomillashtirib boriladi. Shu boisdan 2.2-bandda 
                        ko‘rsatib o‘tilgan sahifalar ijrochi tomonidan taxrirlanishi mumkin.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>2.4.</b> Ijrochi tizimdan foydalanish vaqtida foydalanuvchi tomonidan ishlatilgan qurilmalar 
                        (kompyuter, mobil telefon), dasturiy vositalar (brauzer), shu bilan birga internet tarmog‘ining 
                        ish faoliyati va sifati kabi o‘z vakolatida bo‘lmagan omillar uchun javobgar emas.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>2.5.</b> Ijrochi tizimning xavfsiz va barqaror ishlashi uchun barcha chora-tadbirlar, dasturiy-texnik 
                        yechimlarni ishlab chiqish va joriy etish uchun ma'suldir, o'z navbatida foydalanuvchi ushbu 
                        tizimdan foydalanish orqali qurilmaga yoki qurilma faoliyatini boshqaruvchi operatsion tizimga 
                        yetkazilishi mumkin bo'lgan zararlar uchun javobgarlikni o'z zimmasiga oladi.
                    </p>
                </div>


                <div className='section-container section-sep'>
                    <p className='section-label'>3. Ma’lumotlardan foydalanish</p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>3.1.</b> Tizim foydalanuvchilar bilim olishi va/yoki ulashishi uchun axborot mazmunidagi fanlar 
                        bo‘yicha mutaxassislar tomonidan yozilgan maqolalar va postlardan tashkil topgan.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>3.2.</b> Tizimda berib boriladigan ma’lumotlar, xususan 3.1-bandda ko‘rsatilgan ma’lumotlar axborot 
                        mazmuniga ega bo‘lib, ular mutaxassislar tomonidan o‘quv qo‘llanmalari, darsliklar va boshqa manbalardagi 
                        ma’lumotlar asosida tayyorlangan bo‘lib, ijrochi ushbu ma’lumotlarning to‘g‘riligiga kafolat bermaydi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>3.3.</b> Noaniqliklar yuzaga kelganida va/yoki foydalanuvchida ma’lumotlarning to‘g‘riligiga shubha paydo 
                        bo‘lganida ishonchli manbalarda ko‘rsatilgan ma’lumotlar to‘g‘riligi tan olinadi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>3.4.</b> Tizimdagi ma’lumotlardan foydalanuvchi tizimda ro‘yxatdan o‘tgan holda bevosita, va ro‘yxatdan 
                        o‘tmagan holda ulashish orqali tizimga kirmagan holda foydalanishi mumkin.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>3.5.</b> Foydalanuvchi tizimda e’lon qilingan ma’lumotlarda xatolik aniqlasa <b>info@ttt.org.uz</b> elektron 
                        pochta manziliga xatolik haqida ogohlantirish qoldirish huquqiga ega. Ijrochi mazkur xatolikni 
                        ishonchli manbalar orqali qayta tekshirgan holda, ma’lumotlarni tahrirlashi yoki o‘zgarishsiz qoldirishi mumkin.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>3.6.</b> Tizimdagi ma’lumotlardan faqat shaxsiy maqsadlarda foydalanilishi lozim. Ularni tijorat maqsadida 
                        elektron va/yoki qog‘oz shaklda ko‘paytirish va tarqatish yoki turli bot dasturlar (avtomatlashtirilgan skriptlar va ssenariylar) 
                        yordamida tizimdagi ma’lumotlarni ko‘chirib olish man etiladi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>3.7.</b> Tizimdagi ma’lumotlar joylashgan sahifalarga boshqa veb-saytlardan havola berish yoki sahifalarni 
                        freym-kod(frame, iframe)lar orqali boshqa veb-saytlarga joylashtirish ijrochi ruxsati bilan amalga oshiriladi.
                    </p>
                </div>


                <div className='section-container section-sep'>
                    <p className='section-label'>4. Shaxsiy ma’lumotlar daxlsizligi</p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.1.</b> Shaxsiy ma’lumotlar deganda tizimda ro‘yxatdan o‘tish yoki ilovadan foydalanish jarayonida 
                        foydalanuvchi ixtiyori bilan taqdim etiladigan quyidagi ma’lumotlar tushuniladi:
                    </p>
                    <p className='section-text'>
                        <b>•</b> Mobil telefon raqam - Foydalanuvchi timizga ro‘yxatdan o‘tish jarayonida keltirib o‘tishi va tasdiqlashi zarur bo‘lgan <b>talab etiladigan</b> ma’lumot;
                    </p>
                    <p className='section-text'>
                        <b>•</b> Parol - Foydalanuvchi timizga ro‘yxatdan o‘tish jarayonida keltirishi <b>talab etiladigan</b> ma’lumot;
                    </p>
                    <p className='section-text'>
                        <b>•</b> Ism - Ro‘yxatdan o‘tgan foydalanuvchi tomonidan keltirib o‘tilishi mumkin bo‘lgan <b>talab etilmaydigan</b> ma’lumot;
                    </p>
                    <p className='section-text'>
                        <b>•</b> Familiya - Ro‘yxatdan o‘tgan foydalanuvchi tomonidan keltirib o‘tilishi mumkin bo‘lgan <b>talab etilmaydigan</b> ma’lumot;
                    </p>
                    <p className='section-text'>
                        <b>•</b> Fotosurat - Ro‘yxatdan o‘tgan foydalanuvchi tomonidan keltirib o‘tilishi mumkin bo‘lgan <b>talab etilmaydigan</b> ma’lumot;
                    </p>
                    <p className='section-text'>
                        <b>•</b> Mobil ilovadan foydalangan taqdirda qurilmaning ma'lumotlari (operatsion tizim versiyasi, modeli, identifikatsion nomeri);
                    </p>
                    <p className='section-text'>
                        <b>•</b> Foydalanuvchi tomonidan foydalanilgan ma'lumotlar.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.2.</b> Tizimda ro‘yxatdan o‘tish va/yoki ilovadan foydalanish jarayonida foydalanuvchi taqdim etadigan shaxsiy ma’lumotlar 
                        to‘g‘riligi va aniqligiga uning o‘zi javobgar hisoblanadi. O‘zga shaxslarning shaxsiy ma’lumotlari (mobil telefon raqam, ism, familiya, 
                        fotosurat)ni taqdim etish qonunga asosan taqiqlanadi. Agar foydalanuvchi ro‘yxatdan o‘tish va/yoki ilovadan foydalanish jarayonida 
                        noto‘g‘ri ma’lumotlarni kiritganligi aniqlansa, ijrochi ushbu akkauntni tizimdan o‘chirish huquqini o‘zida saqlab qoladi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.3.</b> Ijrochi tomonidan shaxsiy ma’lumotlarning oshkor etilmasligi va ularning dasturiy-texnik asosda tizim xotirasida 
                        daxlsiz saqlanishi kafolatlanadi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.4.</b> Foydalanuvchi tizimga taqdim etgan shaxsiy ma’lumotlarini sir saqlashi va boshqa shaxslar ixtiyoriga topshirmaslik 
                        bo‘yicha majburiyatni o‘z zimmasiga oladi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.5.</b> Tizimda bir foydalanuvchining identifikatsiyalash ma’lumotlari boshqa bir shaxslar, bot dasturlar 
                        (avtomatlashtirilgan ssenariylar, skriptlar) tomonidan ishlatilayotganligi aniqlangan taqdirda, ijrochi shu foydalanuvchini ogohlantirmasdan
                        uning akkauntini tizimdan o‘chirish va IP-manzilini bloklash huquqini o‘zida saqlab qoladi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.6.</b> Foydalanuvchining mobil telefon raqami uni tizimda identifikatsiyalash, va o‘z shaxsiy ma’lumotlarni taxrirlash 
                        uchun SMS xabarnomalar jo‘natish uchun ishlatiladi. 
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.7.</b> Agar foydalanuvchining akkauntidan foydalanish huquqi uchinchi shaxslar tomonidan turli yo‘llar bilan 
                        o‘zlashtirib olinganligi ma’lum bo‘lsa, ijrochi foydalanuvchi uchun o‘z akkauntini qayta tiklash imkoniyatini yaratib berishga majbur. 
                        Biroq akkaunt uchinchi shaxs qo‘lida bo‘lgan vaqtda amalga oshirilgan harakatlar natijasida yetkazilgan zararlarni ijrochi 
                        qoplash majburiyatini o‘z zimmasiga olmaydi, ijrochining aybi bilan sodir etilgan hollar bundan mustasno. 
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.8.</b> Foydalanuvchi o'z akkauntini istalgan vaqtda tizimdan o'chirish huquqiga ega. 
                        Bu <a className='privacy-url' href="https://ttt.org.uz/users/delete">Sozlamalar</a> bo'limiga o‘tish orqali amalgan oshiriladi. 
                        Bu holatda foydalanuvchi akkaunti va unga tegishli ma'lumotlar tizim xotirasidan qayta tiklanmaydigan darajada o'chiriladi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>4.9.</b> Tizimdan akkaunt hamda shaxsiy ma’lumotlarning o‘chirilishini foydalanuvchi talabnomasiga binoan amalga oshirilishini 
                        ijrochi o‘z zimmasiga olmaydi. Bunday holatda foydalanuvchi yuqorida 4.8-bandda keltirib o‘tilgan tartibda o‘zi amalga oshirishi talab etiladi. 
                    </p>
                </div>


                <div className='section-container section-sep'>
                    <p className='section-label'>5. Boshqa shartlar</p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>5.1.</b> Ushbu ommaviy oferta ijrochi va foydalanuvchi o‘rtasidagi qonuniy bitim hisoblanib, foydalanuvchi va 
                        ijrochi o‘rtasidagi huquq va majburiyatlarni belgilaydi. O‘z navbatida ushbu ommaviy ofertada belgilangan tomonlarning 
                        huquq va majburiyatlari, va ofertada nazarda tutilmagan masalalar O‘zbekiston Respublikasining amaldagi qonunchiligi asosida tartibga solinadi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>5.2.</b> Ijrochi ham, foydalanuvchi ham ushbu ommaviy oferta shartlarini qabul qilish va bajarish vaqtida boshqa 
                        bir uchinchi tomon oldida hech qanday majburiyatlarni o‘z zimmasiga olmaydi, yozma kelishuvsiz o‘z huquq va 
                        majburiyatlarni uchinchi shaxsga o‘tkazmaydi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>5.3.</b> Foydalanuvchi hamda ijrochi o‘rtasida nizo kelib chiqqan taqdirda, eng avvalo o‘zaro kelishuv asosida hal qilishga 
                        harakat qilinadi. Buning imkoni bo‘lmaganda nizo qonunda belgilangan tartibda hal qilinadi.
                    </p>
                </div>

                <div className='section-container'>
                    <p className='section-text'>
                        <b>5.4.</b> Ofertaning biron bandi ayrim sabablarga ko‘ra haqiqiy emas deb topilsa yoki yuridik kuchga ega emas deb baholansa, 
                        bu ofertaning qolgan bandlarining haqiqiyligiga ta’sir qilmaydi.
                    </p>
                </div>
                
                <div className='section-container'>
                    <p className='section-text'>
                        <b>5.5.</b> Ijrochi ommaviy oferta shartlarini o‘zgartirish yoki qayta ko‘rib chiqish huquqiga ega. Bunday o‘zgarishlar haqida 
                        ijrochi kamida 5 ish kuni oldin foydalanuvchilarga tizimning veb saytida barcha ochiq o‘qishi mumkin bo‘lgan joyda e’lon bergan holda ogohlantirishi zarur. 
                        O‘zgarishlar kuchga kirgandan so‘ng foydalanuvchining tizim xizmatlaridan foydalanishi, uning yangi shartlarga roziligini bildiradi.
                    </p>
                </div>

                <div style={{height: 70, backgroundColor: 'rgb(255, 255, 255)'}}/>
            </div>
        </div>
    )
}