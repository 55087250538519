import React from 'react'
import { MathJax, MathJaxContext } from 'better-react-mathjax'


const config = {
    loader: { 
        load: ["[tex]/html"] 
    },
    tex: {
        packages: { 
            "[+]": ["html"] 
        },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
        ],
        displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
        ]
    }
}


export default function RenderContent({ content, width = 370}) {
  return (
    <div style={{width: width, backgroundColor: 'rgb(255, 255, 255)', padding: '7px 15px', fontSize: 17 }}>
      <MathJaxContext config={config}>
        <MathJax 
          inline 
          dynamic
        >
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </MathJax>
      </MathJaxContext>
    </div>
  )
}
