import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/logo.png'


export default function Home() {
    return (
        <div className='home'>
            <div className='home-body'>
                <img src={logo} alt='' className='home-logo'/>
                <p className='home-name'>TA’LIMDA TO‘G‘RI TANLOV</p>
            </div>

            <div className='footer'>
                <div className='footer-container'>
                    <Link to="privacy" className='footer-btn'>Maxfiylik</Link>
                    <Link to="users/delete" className='footer-btn'>Sozlamalar</Link>
                    <Link to="login" className='login-btn'>Kirish</Link>
                </div>
                
                <div className='home-firm'>©2024 TTTUTORIALS. Barcha huquqlar himoyalangan.</div>
            </div>
        </div>
    )
}