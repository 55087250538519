import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import ComponentLoading from '../common/ComponentLoading'


export default function Account() {
    const api = useAxios('public')
    const [loading, setLoading] = useState(false)
    const [info, setInfo] = useState({})
    const [url, setUrl] = useState('')

    useEffect(() => {
        const getInfo = async () => {
            try {
                setLoading(true)

                const response = await api.get('/users/info')
                setInfo(response.data)
            } catch (error) {
                toast.error('Failed to get user info: ' + error.message)
            } finally {
                setLoading(false)
            }
        }

        getInfo()
    }, [])



    const changeAccountImage = async () => {
        if (!url || url.length < 30) {
            toast.error('Enter valid url')
            return
        }

        try {
            setLoading(true)

            await api.put(`/users/info`, {
                ...info,
                imageUrl: url
            })
    
            toast.success('Image has been updated succesfully')
        } catch (error) {
            toast.error('Could not update image try again: ' + error.message)
        } finally {
            setLoading(false)
        }
    }


    if (loading) {
        return <ComponentLoading />
    }


    return(
        <div style={{backgroundColor: 'rgb(249, 253, 255)'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img src={info.imageUrl} alt="rasm" style={{ width: 145, height: 'auto', alignSelf: 'center', margin: 10}}/>          
                <div style={{marginLeft: 10}}>
                    <p style={{fontSize: 33, fontWeight: '700', color: 'rgb(0, 0, 0)'}}>{info.firstName} {info.lastName}</p>
                    <p style={{fontSize: 27, fontWeight: '500', color: 'rgb(102, 113, 114)'}}>{info.styledPhone}</p>
                    <p style={{fontSize: 22, fontWeight: '500', color: 'rgb(112, 123, 124)'}}>{info.enrolDate}</p>
                    <p style={{fontSize: 20, fontWeight: '700', color: 'rgb(147, 81, 22)', backgroundColor: 'rgb(253, 235, 208)', width: 100, padding: 5, borderRadius: 10}}>{info.accessType}</p>
                </div>
            </div>

            <div style={{display: 'flex', backgroundColor: 'rgb(214, 234, 248)', height: 70, marginTop: 20}}>

            </div>
            <div style={{display: 'flex', margin: '5px 0'}}>
                <p style={{fontSize: 18, fontWeight: '700', width: 100}}>Oddiy link:</p>
                <textarea 
                    type='text'
                    style={{ flex: 1, height: 30, fontSize: 20, resize: 'none', paddingLeft: 7}}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                { loading ? (
                    <button
                        style={{width: 150, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(149, 165, 166)', border: 'none', margin: '15px 6px 6px 0', borderRadius: 5}}
                    > O'zgartirish </button>
                ) : (
                    <button
                        style={{width: 150, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(39, 174, 96)', border: 'none', margin: '15px 6px 6px 0', borderRadius: 5}}
                        onClick={changeAccountImage}
                    > O'zgartirish </button>
                )}
            </div>
        </div>
    )
}
