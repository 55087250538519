import React, { useState } from 'react'


export default function Difficulty({ name, selected = true , handleClick = () => {}}) {
    const [hovered, setHovered] = useState(false)

    const getColor = (value) => {
        const color = value === 'EASY' ? styles.easy : value === 'MEDIUM' ? styles.medium : styles.hard
        
        return selected || hovered ? color : 'rgb(233, 247, 239)'
    }

    return(
        <p 
            style={{ ...styles.difficulty, backgroundColor: getColor(name) }}
            onClick={handleClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {name}
        </p>
    )
}


const styles = {
    difficulty: {
        height: 25, 
        fontSize: 17,
        fontWeight: '600',
        border: 'none',
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 20,
        color: 'rgb(25, 25, 25)',
        backgroundColor: 'rgb(46, 204, 113)',
        cursor: 'pointer',
        userSelect: 'none',
    },
    easy: 'rgb(125, 206, 160)',
    medium: 'rgb(248, 196, 113)',
    hard: 'rgb(241, 148, 138)'
}
