import React, { memo } from 'react'

import Toggle from '../../common/Toggle'
import Edit from '../../common/Edit'
import Delete from '../../common/Delete'
import Author from '../../common/Author'
import RenderContent from '../../common/RenderContent'


const SolutionDetails = ({ solution, handlePut, handleDelete, changeVisibility }) => {
    return(
        <div style={styles.container}>
            <div style={styles.actionsWrapper}>
                <Toggle on={solution.visible} action={() => changeVisibility(solution.id)}/>

                <Edit action={() => handlePut(solution)}/>

                <Delete action={() => handleDelete(solution.id)}/>
            </div>

            <Author author={solution.createdBy} date={solution.createdDate} action='posted'/>
            <Author author={solution.updatedBy} date={solution.updatedDate} action='edited'/>

            <div style={styles.content}>
                <RenderContent content={solution.content}/>
            </div>
        </div>
    )
}


const areEqual = (prevProps, nextProps) => {
    return  prevProps.solution.id === nextProps.solution.id && 
            prevProps.solution.visible === nextProps.solution.visible 
}


export default memo(SolutionDetails, areEqual)


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 400, 
        backgroundColor: 'rgb(255, 248, 235)', 
        border: '1px solid rgb(160, 64, 0)', 
        borderRadius: 20, 
        padding: 10, 
        margin: 7
    },
    actionsWrapper: {
        display: 'flex', 
        alignItems: 'center', 
        height: 35, 
        justifyContent: 'flex-end'
    },
    content: {
        margin: '7px 0'
    }
}