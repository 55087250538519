import React, { useState } from 'react'


export default function Tags({ tags, tagsId, setter }) {
    const handleTagChange = (tagId) => {
        const ids = tagsId.includes(tagId) ? tagsId.filter(id => id !== tagId) : [...tagsId, tagId]
        
        setter(ids)
    }


    return(
        <div style={styles.tagsWrapper}>
            { tags.map(tag => (<Tag key={tag.id} name={tag.name} selected={tagsId.includes(tag.id)} handleClick={() => handleTagChange(tag.id)}/>)) }
        </div>
    )
}


function Tag({ name, selected, handleClick }) {
    const [hovered, setHovered] = useState(false)

    return(
        <div 
            style={{ backgroundColor: hovered || selected ? 'rgb(52, 152, 219)' : 'rgb(228, 236, 242)', ...styles.containeer}}
            onClick={handleClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <p style={{color: hovered || selected ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)', ...styles.name}}>{name}</p>
        </div>
    )
}


const styles = {
    containeer: {
        borderRadius: 15, 
        padding: '6px 12px', 
        marginTop: 8,
        marginRight: 10, 
        border: '1.2px solid rgb(127, 179, 213)', 
        cursor: 'pointer'
    },
    name: {
        fontSize: 17, 
        fontWeight: '600', 
        userSelect: 'none'
    },
    tagsWrapper: {
        display: 'flex',
        flex: 1, 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
    },
}
