import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AuthProvider } from './auth/AuthContext'
import Home from './pages/info/Home'
import Privacy from './pages/info/Privacy'
import Login from './pages/user/Login'
import Delete from './pages/user/Delete'
import DeleteVerify from './pages/user/DeleteVerify'
import ProtectedRoute from './pages/common/ProtectedRoute'
import Subjects from './pages/subject/Subjects'
import Files from './pages/file/Files'
import Forums from './pages/forum/Forums'
import Forum from './pages/forum/Forum'
import Solutions from './pages/forum/Solutions'
import Account from './pages/account/Account'
import SpecialTests from './pages/special-test/SpecialTests'

import ArticlePost from './pages/article/ArticlePost'


export default function App() {
  return (
    <div className='app'>
      <AuthProvider>
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="privacy" element={<Privacy />}/>
              <Route path="users/delete" element={<Delete />}/>
              <Route path="users/delete/verify" element={<DeleteVerify />}/>
              
              <Route path="resources" element={<ProtectedRoute />}>
                <Route path="subjects" element={<Subjects />}/>
                <Route path="files" element={<Files />}/>
                <Route path="forums" element={<Forums />}/>
                <Route path="forums/:forumId/problems" element={<Forum />}/>
                <Route path="forums/:forumId/problems/:problemId/solutions" element={<Solutions />}/>
                <Route path="special-tests" element={<SpecialTests />}/>

                <Route path="account" element={<Account />}/>
                <Route path="articles" element={<ArticlePost />}/>
              </Route>
            </Routes>
          </BrowserRouter>

        <ToastContainer />
      </AuthProvider>
    </div>
  )
}
