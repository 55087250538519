import React, { useState } from 'react'

import Tag from './Tag'


export default function SubjectTags({subject, addTag, updateTag, deleteTag}) {
    const [name, setName] = useState('')

    const tags = subject.tags.map(tag => (<Tag key={tag.id} tag={tag} updateTag={updateTag} deleteTag={deleteTag}/>))


    return(
        <div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 70, padding: '0 20px', backgroundColor: 'rgb(234, 237, 237)'}}>
                <p style={{fontSize: 30, fontWeight: '700', flex: 1}}>{subject.name}</p>

                <textarea 
                    type='text'
                    style={{ width: 350, height: 30, paddingLeft: 7, fontSize: 22, resize: 'none'}} 
                    value={name || ''}
                    placeholder='yangi tag nomi'
                    onChange={(e) => setName(e.target.value)}
                />

                <button
                style={{width: 110, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(39, 174, 96 )', border: 'none', marginLeft: 7}}
                    onClick={() => {addTag(subject.id, name); setName('')}}
                > saqlash </button>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: 'rgb(255, 255, 255)', padding: 10}}>
                {tags}
            </div>
        </div>
    )
}
