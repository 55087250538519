import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import useAxios from '../../../auth/useAxios'
import Close from '../../common/Close'
import ActionModal from '../../common/ActionModal'
import ComponentLoading from '../../common/ComponentLoading'
import Subject from './Subject'


export default function SubjectPicker({ subject = {id: 0}, setSubject }) {
    const api = useAxios('protected')
    const navigate = useNavigate()
    const [subjects, setSubjects] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getSubjects()
    }, [])

    const getSubjects = async () => {
        try {
            setLoading(true)

            const response = await api.get("/subjects")

            setSubjects(response.data)
        } catch (error) {
            toast.error('Failed to GET(subjects): ' + error.message)
        } finally {
            setLoading(false)
        }
    }


    if (loading) {
        return <ComponentLoading />
    }


    return(
        <ActionModal children={(
            <div style={styles.wrapper}>
                <div style={styles.actionsWrapper}>
                    <p style={styles.modalName}>Fan tanlang</p>

                    <Close loading={loading} close={() => navigate(-1)}/>
                </div>


                <div style={styles.content}>
                    { subjects.map(subject => (<Subject key={subject.id} name={subject.name} handleClick={() => setSubject(subject)}/>)) }
                </div>
            </div>
        )} />
    )
}


const styles = {
    wrapper: {
        width: 700,
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 20,
        borderRadius: 10, 
        backgroundColor: 'rgb(249, 253, 255)', 
        boxShadow: '0 2px 7px rgb(125, 125, 125)'
    },
    actionsWrapper: {
        display: 'flex', 
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 50,
        marginTop: 7,
    },
    modalName: {
        flex: 1,
        fontSize: 25,
        fontWeight: '700'
    },
    content: {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
    },
}