import React, { memo } from 'react'

import See from '../../common/See'
import Edit from '../../common/Edit'
import Delete from '../../common/Delete'
import Select from '../../common/Select'
import Difficulty from './Difficulty'
import Usage from './Usage'
import RenderContent from '../../common/RenderContent'


const SpecialTestDetails = ({ test, handlePut, handleDelete, handleSee, handleSelect, selectable = false, selected = false}) => {
    return(
        <div style={{...styles.container, backgroundColor: selected ? 'rgb(184, 224, 251)' : 'rgb(230, 240, 246)'}}>
            <div style={styles.actionsWrapper}>
                <Difficulty name={test.difficulty}/>
                
                <Usage value={test.usageCount}/>

                <See action={() => handleSee(test)}/>

                { selectable &&  <Select on={selected} action={() => handleSelect(test)}/> }

                { !selectable &&  <Edit action={() => handlePut(test)}/> }

                { !selectable &&  <Delete action={() => handleDelete(test.id)}/> }
            </div>


            <div style={styles.content}>
                <RenderContent content={test.question} />
            </div>
        </div>
    )
}


const areEqual = (prevProps, nextProps) => {
    return  prevProps.test.id === nextProps.test.id && 
            prevProps.selectable === nextProps.selectable && 
            prevProps.selected === nextProps.selected
}


export default memo(SpecialTestDetails, areEqual)


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 400, 
        border: '1px solid rgb(52, 152, 219)', 
        borderRadius: 20, 
        padding: 10, 
        margin: 7
    },
    actionsWrapper: {
        display: 'flex', 
        alignItems: 'center', 
        height: 35, 
        justifyContent: 'flex-end'
    },
    content: {
        margin: '7px 0'
    },
}