import React, { useState } from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'


export default function Delete({ action }) {
    const [hovered, setHovered] = useState(false)


    return(
        <RiDeleteBin6Line 
            style={{ backgroundColor: hovered ? 'rgb(250, 219, 216)' : '', ...styles.action}}
            onClick={action}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        />
    )
}


const styles = {
    action: {
        height: 19, 
        width: 19, 
        color: 'rgb(203, 67, 53)',
        marginRight: 5,
        padding: 7,
        cursor: 'pointer'
    }
}
