import React from 'react'


export default function ActionModal({ children }) {
    return(
        <div style={styles.container}>
            {children}
        </div>
    )
}


const styles = {
    container: {
        position: 'fixed', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        padding: 70,
        display: 'flex', 
        flexDirection: 'column', 
        overflow: 'auto', 
        scrollbarWidth: 'none', 
        msOverflowStyle: 'none',
        backgroundColor: 'rgba(65, 83, 116, 0.8)',
        zIndex: 1000
    }
}
