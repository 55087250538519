import React from 'react'


export default function ProblemDetails({ author, date, action }) {
    return(
        <div style={styles.container}>
            <img src={author.imageUrl} style={styles.image}></img>
            <div style={{marginLeft: 10}}>
                <p style={styles.name}>{author.fullName}</p>
                <p style={styles.date}>{date} ({action})</p>
            </div>
        </div>
    )
}


const styles = {
    container: {
        display: 'flex', 
        alignItems: 'center',
        margin: '3px 0'
    },
    image: {
        width: 50, 
        height: 50, 
        borderRadius: 25, 
        userSelect: 'none'
    },
    name: {
        fontSize: 20, 
        fontWeight: '700',
        userSelect: 'none'
    },
    date: {
        fontSize: 17,
        userSelect: 'none'
    }
}