import React, { useState } from 'react'
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io'


export default function Select({ on, action }) {
    const [hovered, setHovered] = useState(false)


    return(
        <> 
            {on ? (
                <IoIosRadioButtonOn 
                    style={{ backgroundColor: hovered ? 'rgb(192, 246, 214)' : '', ...styles.action}}
                    onClick={action}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                />
            ) : (
                <IoIosRadioButtonOff 
                    style={{ backgroundColor: hovered ? 'rgb(229, 232, 232)' : '', ...styles.action, color: 'rgb(93, 109, 126)'}}
                    onClick={action}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                />
            )}
        </>
    )
}


const styles = {
    action: {
        height: 23, 
        width: 23, 
        color: 'rgb(35, 155, 86)',
        marginRight: 5,
        padding: 5,
        cursor: 'pointer'
    }
}
