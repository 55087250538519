import React, { useContext } from 'react'
import axios from 'axios'

import baseUrl from './baseUrl'
import { AuthContext } from './AuthContext'


const useAxios = (route) => {
    const { updateToken } = useContext(AuthContext)
    const token = localStorage.getItem('access_token')

    const axiosInstance = axios.create({
        baseURL: `${baseUrl}/${route}`,
        timeout: 30000,
        headers: token ? {
            Authorization: `Bearer ${token}`,
        } : {},
    })


    axiosInstance.interceptors.response.use(
        (response) => {
            return response
        },
        async (error) => {
            const originalRequest = error.config

            // if error.code is 401 and this is first attempt then retry
            if (error.response.status === 401 && !originalRequest._retry) {
                // update retry attempt value
                originalRequest._retry = true

                // update auth state new access token
                await updateToken()

                // get new access_token
                const tkn = localStorage.getItem('access_token')
                originalRequest.headers['Authorization'] = `Bearer ${tkn}`
                
                return axiosInstance(originalRequest)
            }

            return Promise.reject(error)
        }
    )

    return axiosInstance
}


export default useAxios