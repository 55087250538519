import React from 'react'
import ReactLoading from 'react-loading'


export default function ComponentLoading() {
    return (
        <div style={{height: 500, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ReactLoading type="bars" color="#0000ff" height={100} width={100}/>
        </div>
    )
}