import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import ComponentLoading from '../common/ComponentLoading'
import SubjectTags from './components/SubjectTags'


export default function Subjects() {
    const api = useAxios('protected')
    const [subjects, setSubjects] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getSubjects()
    }, [])

    const getSubjects = async () => {
        try {
            setLoading(true)

            const response = await api.get("/subjects")

            setSubjects(response.data)
        } catch (error) {
            toast.error('Failed to GET(subjects): ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const addTag = async (subjectId, tagName) => {
        try {
            setLoading(true)

            await api.post(`/subjects/${subjectId}/tags`, {
                name: tagName
            })

            toast.success('Tag has been added successfully')
            getSubjects()
        } catch (error) {
            toast.error('Failed to add new tag: ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const updateTag = async (subjectId, tagId, tagName) => {
        try {
            setLoading(true)

            await api.put(`/subjects/${subjectId}/tags/${tagId}`, {
                name: tagName
            })

            toast.success('Tag has been updated successfully')
            getSubjects()
        } catch (error) {
            toast.error('Failed to update the tag: ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const deleteTag = async (subjectId, tagId) => {
        const res = window.confirm('Want to delete this tag')

        if (!res) {
            return
        }

        try {
            setLoading(true)

            await api.delete(`/subjects/${subjectId}/tags/${tagId}`)

            toast.success('Tag has been deleted successfully')
            getSubjects()
        } catch (error) {
            toast.error('Failed to update the tag: ' + error.message)
        } finally {
            setLoading(false)
        }
    }


    if (loading) {
        return <ComponentLoading />
    }


    return(
        <div>
            { subjects.map(subject => (<SubjectTags key={subject.id} subject={subject} addTag={addTag} updateTag={updateTag} deleteTag={deleteTag}/>)) }
        </div>
    )
}
