import React from 'react'

import likeCount from '../../assets/likeCount.jpg'
import saveCount from '../../assets/saveCount.jpg'
import seenCount from '../../assets/seenCount.jpg'
import shareCount from '../../assets/shareCount.jpg'


export default function Statistics({ like, save, see, share }) {
    return(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 3, flex: 1}}>
            <img src={likeCount} alt='like count' style={styles.icon}/> <b style={styles.count}>{like}</b>
            <img src={saveCount} alt='save count' style={styles.icon}/> <b style={styles.count}>{save}</b>
            <img src={seenCount} alt='see count' style={styles.icon}/> <b style={styles.count}>{see}</b>
            <img src={shareCount} alt='share count' style={styles.icon}/> <b style={styles.count}>{share}</b>
        </div>
    )
}


const styles = {
    icon: {
        height: 20,
        width: 20,
        marginRight: 5
    },
    count: {
        fontSize: 17,
        marginRight: 10
    }
}
