import React, { useState } from 'react'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import File from './component/File'

export default function Files() {
    
    const api = useAxios('protected')
    const [file, setFile] = useState(null)
    const [urls, setUrls] = useState([])
    const [loading, setLoading] = useState(false)

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        setFile(file)
    }

    const saveFile = async () => {
        try {
            setLoading(true)
            
            const formData = new FormData()
            formData.append('file', file)

            const response = await api.post('/files', formData)

            setUrls(prev => [response.data, ...prev])
            setFile(null)
        } catch (error) {
            toast.error('Failed to upload the file: ' + error.message)
        } finally {
            setLoading(false)
        }
    }


    return(
        <div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgb(242, 243, 244)'}}>
                { file && <img src={URL.createObjectURL(file)} alt="Tanlangan rasm" style={{ width: 400, height: 'auto', margin: 10 }} />}
                <div style={{display: 'flex', flexDirection: 'row', height: 40, width: '100%', alignItems: 'center', padding: 10, backgroundColor: 'rgb(209, 242, 235)'}}>
                    <p style={{fontSize: 20, fontWeight: '700', flex: 1, marginLeft: 20}}>Yangi rasm yuklash</p>
                    <input 
                        type="file" 
                        accept="image/*"
                        style={{fontSize: 20, color: 'rgb(0, 0, 0)', backgroundColor: 'rgb(242, 243, 244)', marginRight: 20}} 
                        onChange={handleFileChange}
                    />
                    { loading ? (
                        <button
                            style={{width: 110, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(174, 182, 191)', border: 'none',  marginRight: 20}}
                        > saqlash </button>
                    ) : (
                        <button
                            style={{width: 110, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(39, 174, 96)', border: 'none',  marginRight: 20}}
                            onClick={saveFile}
                        > saqlash </button>
                    )}
                </div>
            </div>

            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: 30, backgroundColor: 'rgb(242, 248, 252)'}}>
                <p style={{fontSize: 27, fontWeight: '700', marginTop: 30}}>SAQLANGAN RASMLAR</p>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: 'rgb(242, 248, 252)', padding: 17}}>
                { urls.map(url => (<File url={url}/>)) }
            </div>
        </div>
    )
}
