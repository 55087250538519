import React, { useState } from 'react'
import { toast } from 'react-toastify'

import useAxios from '../../../auth/useAxios'
import Apply from '../../common/Apply'
import Close from '../../common/Close'
import TextInput from '../../common/TextInput'
import RenderContent from '../../common/RenderContent'


export default function SolutionWrite({ solution, problemId, type, cancel }) {
    const api = useAxios('protected')
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(solution)


    const validate = (solution) => {
        if (!solution.content || solution.content.length < 30) {
            throw new Error("Javob kamida 30 ta belgidan iborat bo'lishi kerak")
        }
    }

    const postSolution = async () => {
        try {
            setLoading(true)

            validate(current)

            await api.post(`/problems/${problemId}/solutions`, current)
    
            cancel()
            toast.success('Yechim saqlandi.')
        } catch (error) {
            if (error.data) {
                toast.error('Yechim saqlashda muammo vujudga keldi: ' + error.data)
            } else {
                toast.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }

    const putSolution = async () => {
        try {
            setLoading(true)

            validate(current)

            await api.put(`/problems/${problemId}/solutions/${solution.id}`, current)
    
            cancel()
            toast.success("Yeshim o'zgartirildi.")
        } catch (error) {
            if (error.data) {
                toast.error("Yechim o'zgartirishda muammo vujudga keldi: " + error.data)
            } else {
                toast.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }


    return(
        <div style={styles.container}>
            <div style={styles.actionsWrapper}>
                <p style={styles.modalName}>Post ma'lumotlari</p>

                { type === 'POST' && <Apply loading={loading} name="QO'SHISH" apply={postSolution}/> }

                { type === 'PUT' && <Apply loading={loading} name="O'ZGARTIRISH" apply={putSolution} color1='rgb(187, 143, 206)' color2='rgb(210, 180, 222)'/> }

                <Close loading={loading} close={cancel}/>
            </div>


            <p style={styles.title}>Javob:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.content || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        content: value
                    }))}
                    ratio={5}
                />


                <div style={styles.shareWrapper}>
                    <RenderContent content={current.content} />
                </div>
            </div>
        </div>
    )
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 20,
        borderRadius: 10, 
        backgroundColor: 'rgb(249, 253, 255)', 
        boxShadow: '0 2px 7px rgb(125, 125, 125)'
    },
    actionsWrapper: {
        display: 'flex', 
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 50,
        marginTop: 7,
    },
    modalName: {
        flex: 1,
        fontSize: 25,
        fontWeight: '700'
    },
    title: {
        fontSize: 18, 
        fontWeight: '700',
        color: 'rgb(40, 40, 40)',
        marginTop: 10,
    },
    contentWrapper: {
        display: 'flex', 
        alignItems: 'flex-start',
    },
    shareWrapper: {
        backgroundColor: 'rgb(255, 255, 255)', 
        marginLeft: 10, 
        border: '1px solid rgb(255, 0, 0)'
    }
}