import React, { useState } from 'react'
import { IoMdClose } from "react-icons/io"


export default function Close({ loading, close }) {
    const [hovered, setHovered] = useState(false)

    const handleClose = () => {
        if (loading) {
            return;
        } else {
            close()
        }
    }


    return(
        <IoMdClose 
            style={{ backgroundColor: hovered ? 'rgb(225, 226, 229)' : '', ...styles.close}}
            onClick={handleClose}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        />
    )
}


const styles = {
    close: {
        height: 30,
        width: 30,
        cursor: 'pointer'
    }
}
