import React, { useState, useRef } from 'react'
import { toPng } from 'html-to-image'
import { toast } from 'react-toastify'

import SubjectTagsList from './SubjectTagsList'
import RenderContent from '../common/RenderContent'
import ShareTemplate from '../common/ShareTemplate'
import useAxios from '../../auth/useAxios'


export default function ArticlePost() {

    const componentRef = useRef(null)
    const api = useAxios('protected')
    const [loading, setLoading] = useState(false)
    const [article, setArticle] = useState({
      subjectId: null,
      tagsId: [],
      title: null,
      description: null,
      content: null,
      shareUrl: null,
    })

    const handleSubjectChange = (id) => {
        setArticle(prev => (
            {
                ...prev,
                subjectId: prev.subjectId === id ? null : id,
                tagsId: []
            }
        ))
    }

    const handleTagChange = (id) => {
        let tagsId = article.tagsId

        if (tagsId.includes(id)) {
            tagsId = tagsId.filter(item => item != id)
        } else {
            tagsId.push(id)
        }

        setArticle(prev => (
            {
                ...prev,
                tagsId: tagsId
            }
        ))
    }

    const getShareUrl = async () => {
        const node = componentRef.current

        try {
            setLoading(true)

            const dataUrl = await toPng(node, { quality: 1, pixelRatio: 2 })
            const response = await fetch(dataUrl)
            const blob = await response.blob()
        
            const formData = new FormData()
            formData.append('file', blob, 'article-description.png')
        
            const res = await api.post('/files', formData)
        
            return res.data
        } catch (error) {
            toast.error('Failed to capture image: ' + error.message)
            throw error
        } finally {
            setLoading(false)
        }
    }


    const handlePost = async () => {
        if (!article.title || article.title.length < 10) {
            toast.error('Title should be at least 10 chars')
            return
        } else if (!article.description || article.description.length < 200) {
            toast.error('Description should be at least 3 chars')
            return
        } else if (!article.content || article.content.length < 200) {
            toast.error('Content should be at least 50 chars')
            return
        } else if (article.tagsId.length === 0) {
            toast.error('At least one tag should be selected')
            return
        }


        try {
            setLoading(true)

            const url = await getShareUrl()

            await api.post('/articles', {
                ...article,
                shareUrl: url
            })

            toast.success('Article has been saved succesfully')
            cancel()
        } catch (error) {
            toast.error('Could not save article try again: ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const cancel = () => {
        setArticle({
            subjectId: null,
            tagsId: [],
            title: null,
            description: null,
            content: null,
            shareUrl: null,
        })
    }


    return(
        <div>
            <SubjectTagsList subjectId={article.subjectId} tagsId={article.tagsId} setSubject={handleSubjectChange} addTag={handleTagChange}/>

            <div style={{padding: '0 10px', backgroundColor: 'rgb(255, 252, 240)'}}>
                <p style={{fontSize: 18, fontWeight: '700'}}>Title:</p>
                <div style={{display: 'flex', margin: '5px 0'}}>
                    <textarea 
                        type='text'
                        style={{ flex: 1, height: 40, fontSize: 20, padding: 10, resize: 'none'}} 
                        value={article.title || ''}
                        onChange={(e) => setArticle(prev => ({
                            ...prev,
                            title: e.target.value
                        }))}
                    />

                    <div style={{backgroundColor: 'rgb(255, 255, 255)', marginLeft: 10, border: '1px solid rgb(255, 0, 0)'}}>
                        <RenderContent content={article.title} width={375}/>
                    </div>
                </div>

                <p style={{fontSize: 18, fontWeight: '700'}}>Description:</p>
                <div style={{display: 'flex', margin: '5px 0'}}>
                    <textarea 
                        type='text'
                        style={{ flex: 1, minHeight: 250, fontSize: 20, padding: 10, resize: 'vertical'}} 
                        value={article.description || ''}
                        onChange={(e) => setArticle(prev => ({
                            ...prev,
                            description: e.target.value
                        }))}
                    />

                    <div style={{backgroundColor: 'rgb(255, 255, 255)', marginLeft: 10, border: '1px solid rgb(255, 0, 0)'}}>
                        <div ref={componentRef}>
                            <ShareTemplate content={article.description}/>
                        </div>
                    </div>
                </div>

                <p style={{fontSize: 18, fontWeight: '700'}}>Content:</p>
                <div style={{display: 'flex', margin: '5px 0'}}>
                    <textarea 
                        type='text'
                        style={{ flex: 1, minHeight: 350, fontSize: 20, padding: 10, resize: 'vertical'}} 
                        value={article.content || ''}
                        onChange={(e) => setArticle(prev => ({
                            ...prev,
                            content: e.target.value
                        }))}
                    />

                    <div style={{backgroundColor: 'rgb(255, 255, 255)', marginLeft: 10, border: '1px solid rgb(255, 0, 0)'}}>
                        <RenderContent content={article.content} width={375}/>
                    </div>
                </div>
            </div>


            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}>
                { loading ? (
                    <button
                        style={{width: 150, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(149, 165, 166)', border: 'none', margin: '15px 6px 6px 0', borderRadius: 5}}
                    > Bekor qilish </button>
                ) : (
                    <button
                        style={{width: 150, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(231, 76, 60)', border: 'none', margin: '15px 6px 6px 0', borderRadius: 5}}
                        onClick={cancel}
                    > Bekor qilish </button>
                )}
                { loading ? (
                    <button
                        style={{width: 150, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(149, 165, 166)', border: 'none', margin: '15px 6px 6px 0', borderRadius: 5}}
                    > Qo'shish </button>
                ) : (
                    <button
                        style={{width: 150, height: 30, fontSize: 20, color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(39, 174, 96)', border: 'none', margin: '15px 6px 6px 0', borderRadius: 5}}
                        onClick={handlePost}
                    > Qo'shish </button>
                )}
            </div>
        </div>
    )
}
