import React, { useState } from 'react'
import { GrEdit } from 'react-icons/gr'


export default function Edit({ action }) {
    const [hovered, setHovered] = useState(false)


    return(
        <GrEdit 
            style={{ backgroundColor: hovered ? 'rgb(235, 222, 240)' : '', ...styles.action}}
            onClick={action}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        />
    )
}


const styles = {
    action: {
        height: 19, 
        width: 19, 
        color: 'rgb(125, 60, 152)',
        marginRight: 5,
        padding: 7,
        cursor: 'pointer'
    }
}
