import React, { useState } from 'react'
import { IoReload } from 'react-icons/io5'


export default function Reload({ loading, action }) {
    const [hovered, setHovered] = useState(false)

    const handleClose = () => {
        if (loading) {
            return;
        } else {
            action()
        }
    }


    return(
        <IoReload 
            style={{ backgroundColor: hovered ? 'rgb(174, 214, 241)' : '', ...styles.action}}
            onClick={handleClose}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        />
    )
}


const styles = {
    action: {
        height: 25,
        width: 25,
        padding: 7,
        color: 'rgb(40, 116, 166)',
        cursor: 'pointer'
    }
}
