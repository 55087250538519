import React from 'react'

import { IoSearch } from 'react-icons/io5'

import logo from '../../assets/logo.png'
import playStore from '../../assets/play-store-icon48.png'
import telegramLogo from '../../assets/tg-logo.jpg'
import RenderContent from './RenderContent'


export default function ShareTemplate({ content }) {
  return (
    <div style={{backgroundColor: 'rgb(255, 255, 255)', paddingBottom: 10}}>
      <RenderContent content={content} />
      <div style={{display: 'flex', padding: '0 10px', height: 25, justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', backgroundColor: 'rgb(255, 255, 255)'}}>
          <img src={logo} style={{width: 26, height: 26}}/>
          <p style={{fontSize: 17, fontWeight: '600', marginBottom: 3, color: 'rgb(0, 0, 0)', marginRight: 7}}>TTT</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center', height: 25, padding: '0 10px', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(203, 205, 209)', borderRadius: 15}}>
          <img src={playStore} style={{height: 19, width: 'auto'}}/>
          <p style={{fontSize: 17, fontWeight: '600', marginBottom: 3, color: 'rgb(105, 110, 116)', marginLeft: 7, marginRight: 10}}>ttt.org.uz</p>
          <IoSearch style={{width: 15, height: 15, color: 'rgb(135, 140, 146)'}}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center', height: 25, padding: '0 10px', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(203, 205, 209)', borderRadius: 15}}>
          <img src={telegramLogo} style={{height: 18, width: 'auto'}}/>
          <p style={{fontSize: 17, fontWeight: '600', marginBottom: 3, color: 'rgb(105, 110, 116)', marginLeft: 7, marginRight: 10}}>ttt_org_uz</p>
          <IoSearch style={{width: 15, height: 15, color: 'rgb(135, 140, 146)'}}/>
        </div>
      </div>
    </div>
  )
}
