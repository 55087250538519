import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import ActionModal from '../common/ActionModal'
import Apply from '../common/Apply'
import Reload from '../common/Reload'
import Tags from '../common/Tags'
import ComponentLoading from '../common/ComponentLoading'
import SubjectPicker from './components/SubjectPicker'
import SpecialTestDetails from './components/SpecialTestDetails'
import Difficulties from './components/Difficulties'
import SpecialTestSee from './components/SpecialTestSee'
import SpecialTestWrite from './components/SpecialTestWrite'
import SpecialBlockSee from './components/SpecialBlockSee'


export default function SpecialTests() {
    const api = useAxios('protected')
    const [tests, setTests] = useState([])
    const [subject, setSubject] = useState(null)
    const [component, setComponent] = useState(null) // stores current component for POST or PUT action, or to pick a subject
    const [selectable, setSelectable] = useState(false) // enables test selection for special block
    const [selectedTests, setSelectedTests] = useState([]) // selected tests list for special block
    const [selectedIds, setSelectedIds] = useState([]) // selected tests' ids'
    const [showBlock, setShowBlock] = useState(false) // opens new window to see special block
    const [loading, setLoading] = useState(false)
    const [difficulties, setDifficulties] = useState([]) // used to filter tests by difficulty
    const [tagsId, setTagsId] = useState([]) // used to filter tests by tag ids
    const [filteredTests, setFilteredTests] = useState() // filtered tests by difficulty and tegsId
    


    useEffect(() => {
        getTests()
    }, [subject])
 
    const getTests = async () => {
        // Load tests only when there is a selected subject
        if (!subject) {
            return
        }

        try {
            setLoading(true)

            const response = await api.get("/special-tests", {
                params: {
                    subjectId: subject.id,
                    maxUsageCount: 0
                }
            })

            setTests(response.data)
        } catch (error) {
            toast.error('Failed to GET(special-tests): ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const handleSee = (test) => {
        setComponent(<SpecialTestSee test={test} cancel={() => setComponent(null)} />)
    }

    const handlePost = () => {
        const current = {
            subjectId: subject.id,
            tagsId: [],
            difficulty: 'EASY',
            question: '',
            trueAnswer: '',
            falseAnswer1: '',
            falseAnswer2: '',
            falseAnswer3: '',
            solution: '',
        }

        setComponent(<SpecialTestWrite test={current} subject={subject} type='POST' cancel={() => setComponent(null)} />)
    }

    const handlePut = (test) => {
        const current = {
            id: test.id,
            subjectId: subject.id,
            tagsId: test.tags.map(tag => tag.id),
            difficulty: test.difficulty,
            question: test.question,
            trueAnswer: test.trueAnswer,
            falseAnswer1: test.falseAnswer1,
            falseAnswer2: test.falseAnswer2,
            falseAnswer3: test.falseAnswer3,
            solution: test.solution,
        }

        setComponent(<SpecialTestWrite test={current} subject={subject} type='PUT' cancel={() => setComponent(null)} />)
    }

    const handleDelete = async (testId) => {
        const res = window.confirm("Maxsus testni o'chirmoqchimisiz?")

        if (!res) {
            return
        }

        try {
            setLoading(true)

            await api.delete(`/special-tests/${testId}`)

            // remove deleted problem from problems list
            setTests(prev => prev.filter(test => test.id !== testId))
            
            toast.success("Maxsus test o'chirildi.")
        } catch (error) {
            toast.error("Maxsus testni o'chirishda muammo vujudga keldi: " + error.data)
        } finally {
            setLoading(false)
        }
    }

    const handleSelect = (test) => {
        setSelectedIds(prevSelectedIds => {
            const alreadySelected = prevSelectedIds.includes(test.id)
        
            if (prevSelectedIds.length === 30 && !alreadySelected) {
                toast.error('You can only select up to 30 tests.')

                return prevSelectedIds // Prevent changes
            }
        
            const newSelectedIds = alreadySelected
                ? prevSelectedIds.filter(id => id !== test.id)
                : [...prevSelectedIds, test.id]
        
            setSelectedTests(prevSelectedTests => 
                alreadySelected 
                    ? prevSelectedTests.filter(t => t.id !== test.id)
                    : [...prevSelectedTests, test]
            )
        
            return newSelectedIds; // Apply changes
        })
    }

    const cancelTestSelection = () => {
        const res = window.confirm('Maxsus blok tuzishni bekor qilmoqchimisiz?')

        if (!res) {
            return
        }

        // stop test selection
        setSelectable(false)

        // clear selected tests
        setSelectedTests([])

        // clear selected test ids
        setSelectedIds([])
    }

    // filter already fetched tests
    useEffect(() => {
        // filter by 'difficulty':
        const firstResult = tests.filter(test => difficulties.length === 0 || difficulties.includes(test.difficulty))

        // filter by 'tags':
        if (tagsId.length === 0) {
            setFilteredTests(firstResult)
        } else {
            const set = new Set(tagsId)

            const secondResult = firstResult.filter(test => test.tags.some(tag => set.has(tag.id)))

            setFilteredTests(secondResult)
        }
        
    }, [tests, difficulties, tagsId])

    const handleDifficultyChange = (d) => {
        setDifficulties(prev => prev.includes(d) ? prev.filter(difficulty => difficulty !== d) : [...prev, d])
    }

    if (subject === null) {
        return <SubjectPicker subject={subject} setSubject={setSubject}/>
    } else if (loading) {
        return <ComponentLoading />
    } else 


    return(
        <div style={styles.container}>
            { showBlock && <ActionModal children={<SpecialBlockSee tests={selectedTests} handleSee={handleSee} handleSelect={handleSelect} cancel={() => setShowBlock(false)} />} /> }
            
            { component && <ActionModal children={component} /> }

            <div style={styles.actionsWrapper}>
                <p style={styles.name}>{subject.name}</p>

                <Difficulties difficulties={difficulties} handleChange={handleDifficultyChange}/>

                <Apply loading={loading} name="Test qo'shish" apply={handlePost} />

                { !selectable &&  <Apply loading={loading} name="Blok tuzish" apply={() => setSelectable(true)} color1='rgb(187, 143, 206)' color2='rgb(210, 180, 222)'/> }

                { selectable &&  <Apply loading={loading} name={`Blokni ko'rish [${selectedIds.length}]`} apply={() => setShowBlock(true)} color1='rgb(245, 176, 65)' color2='rgb(248, 196, 113)'/> }

                { selectable &&  <Apply loading={loading} name="Bekor qilish" apply={cancelTestSelection} color1='rgb(241, 148, 138)' color2='rgb(245, 183, 177)'/> }

                <Reload loading={loading} action={getTests}/>
            </div>

            <div style={styles.tagsWrapper}>
                <Tags tags={subject.tags} tagsId={tagsId} setter={setTagsId}/>
            </div>
            
            <div style={styles.content}>
                { filteredTests.map(test => (
                    <SpecialTestDetails 
                        key={test.id} 
                        test={test} 
                        handlePut={handlePut} 
                        handleDelete={handleDelete}
                        handleSee={handleSee}
                        handleSelect={handleSelect} 
                        selectable={selectable} 
                        selected={selectedIds.includes(test.id)}
                    />
                )) }
            </div>
        </div>
    )
}


const styles = {
    container: {
    },
    actionsWrapper: {
        display: 'flex', 
        flexDirection: 'row', 
        height: 45, 
        width: '100%',
        alignItems: 'center',
        padding: '0 20px',
        boxSizing: 'border-box',
        backgroundColor: 'rgb(234, 237, 237)',
        position: 'sticky',
        top: 0,
        zIndex: 200,
    },
    name: {
        flex: 1,
        fontSize: 25,
        color: 'rgb(20, 20, 20)', 
        fontWeight: '700', 
        userSelect: 'none',
    },
    tagsWrapper: {
        maxHeight: 95,
        overflow: 'auto',
        backgroundColor: 'rgb(234, 237, 237)',
        scrollbarWidth: 'none', 
        msOverflowStyle: 'none',
        padding: '0 15px 5px 15px'
    },
    content: {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        backgroundColor: 'rgb(255, 255, 255)', 
        padding: 10
    }
}