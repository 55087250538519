import React from 'react'


export default function Usage({ value }) {
    return(
        <p style={styles.difficulty}>{value}</p>
    )
}


const styles = {
    difficulty: {
        height: 25, 
        fontSize: 17,
        fontWeight: '700',
        border: 'none',
        borderRadius: 5,
        marginRight: 20,
        paddingLeft: 10,
        paddingRight: 10,
        color: 'rgb(25, 25, 25)',
        backgroundColor: 'rgb(210, 180, 222)',
        userSelect: 'none',
    },
}
