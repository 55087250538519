import React, { useState } from 'react'


export default function Subject({ name, handleClick }) {
    const [hovered, setHovered] = useState(false)

    return(
        <div 
            style={{ backgroundColor: hovered ? 'rgb(108, 52, 131)' : 'rgb(235, 222, 240)', ...styles.containeer}}
            onClick={handleClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <p style={{color: hovered ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)', ...styles.name}}>{name}</p>
        </div>
    )
}


const styles = {
    containeer: {
        borderRadius: 16, 
        padding: '7px 14px', 
        marginTop: 8,
        marginBottom: 8, 
        marginRight: 16, 
        border: '1.2px solid rgb(108, 52, 131)', 
        cursor: 'pointer'
    },
    name: {
        fontSize: 18, 
        fontWeight: '600', 
        userSelect: 'none'
    }
}