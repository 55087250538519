import React, { useState } from 'react'
import { IoSearch } from 'react-icons/io5'


export default function See({ action }) {
    const [hovered, setHovered] = useState(false)


    return(
        <IoSearch 
            style={{ backgroundColor: hovered ? 'rgb(229, 232, 232)' : '', ...styles.action}}
            onClick={action}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        />
    )
}


const styles = {
    action: {
        height: 21, 
        width: 21, 
        color: 'rgb(93, 109, 126)',
        marginRight: 5,
        padding: 6,
        cursor: 'pointer'
    }
}
