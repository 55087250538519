import React, { useState } from 'react'
import { GrEdit } from "react-icons/gr"
import { RiDeleteBin6Line } from "react-icons/ri"
import { IoCheckmarkCircleOutline } from "react-icons/io5"
import { MdOutlineCancel } from "react-icons/md"


export default function Tag({tag, updateTag, deleteTag}) {
    const [name, setName] = useState(tag.name)
    const [updating, setUpdating] = useState(false)


    return(
        <div>
            { updating ?
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 7, height: 38, borderRadius: 12, backgroundColor: 'rgb(214, 234, 248)', border: '1px solid rgb(52, 152, 219)'}}>
                    <textarea 
                        type='text'
                        style={{ fontSize: 22, fontWeight: '500', height: 33, paddingLeft: 7, paddingTop: 5, color: 'rgb(0, 0, 0)', width: 350, resize: 'none', border: 'none', borderRadius: 12}} 
                        value={name || ''}
                        placeholder='yangi tag nomi'
                        onChange={(e) => setName(e.target.value)}
                    />
                    <IoCheckmarkCircleOutline style={{color: 'rgb(34, 153, 84)', height: 22, width: 22, margin: '5px 7px'}} onClick={() => {updateTag(tag.subjectId, tag.id, name); setUpdating(false)}}/>
                    <MdOutlineCancel style={{color: 'rgb(203, 67, 53)', height: 22, width: 22, margin: '5px 7px'}} onClick={() => setUpdating(false)}/>
                </div> :
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 7, height: 30, padding: '4px 7px', borderRadius: 12, backgroundColor: 'rgb(214, 234, 248)', border: '1px solid rgb(52, 152, 219)'}}>
                    <p style={{fontSize: 22, fontWeight: '500', color: 'rgb(0, 0, 0)', marginRight: 10, marginLeft: 7}}>{tag.name}</p>
                    <GrEdit style={{color: 'rgb(125, 60, 152)', height: 19, width: 19, margin: '5px 7px'}} onClick={() => setUpdating(true)}/>
                    <RiDeleteBin6Line style={{color: 'rgb(203, 67, 53)', height: 19, width: 19, margin: '5px 7px'}} onClick={() => deleteTag(tag.subjectId, tag.id)}/>
                </div>
            }
        </div>
        
    )
}
