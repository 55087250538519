import React, { useContext } from 'react'
import { Navigate, Outlet, NavLink, useLocation } from 'react-router-dom'

import { AuthContext } from '../../auth/AuthContext'


export default function ProtectedRoute() {
    const { routes } = useContext(AuthContext)
    const location = useLocation()

    const isAvailable = (nav) => {
        return routes.includes(nav)
    }

    const hasAccess = routes && routes.some(route => location.pathname.includes(route))

    if (!hasAccess) {
        return <Navigate to="/login" />
    }

    
    return (
        <div style={styles.container}>
            <div style={styles.navbar}>
                { isAvailable('subjects') && <NavLink 
                    to="subjects" 
                    style={({isActive}) => ({...styles.link, color: isActive ? styles.active : styles.inactive})}
                >
                    FAN
                </NavLink> }

                { isAvailable('files') && <NavLink 
                    to="files" 
                    style={({isActive}) => ({...styles.link, color: isActive ? styles.active : styles.inactive})}
                >
                    RASM
                </NavLink> }

                { isAvailable('forums') && <NavLink 
                    to="forums" 
                    style={({isActive}) => ({...styles.link, color: isActive ? styles.active : styles.inactive})}
                >
                    FORUM
                </NavLink> }

                { isAvailable('special-tests') && <NavLink 
                    to="special-tests" 
                    style={({isActive}) => ({...styles.link, color: isActive ? styles.active : styles.inactive})}
                >
                    MAXSUS TEST
                </NavLink> }

                {/* { isAvailable('articles') && <NavLink 
                    to="articles" 
                    style={({isActive}) => ({...styles.link, color: isActive ? styles.active : styles.inactive})}
                >
                    MAQOLA
                </NavLink> } */}

                <NavLink 
                    to="account" 
                    style={({isActive}) => ({...styles.link, color: isActive ? styles.active : styles.inactive})}
                >
                    AKKAUNT
                </NavLink>
            </div>
            <Outlet/>
        </div>
    )
}


const styles = {
    container: {
        overflow: 'auto',

        // do not hide scrols
        // scrollbarWidth: 'none', 
        // msOverflowStyle: 'none',
    },
    navbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: 45,
        backgroundColor: 'rgb(31, 97, 141)',
        position: 'sticky',
        top: 0,
        zIndex: 100,
    },
    link: {
        fontSize: 18,
        fontWeight: '600',
        textDecoration: 'none',
        userSelect: 'none',
    },
    inactive: 'rgb(255, 255, 255)',
    active: 'rgb(236, 112, 99)'
}