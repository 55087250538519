import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import Statistics from '../../common/Statistics'
import Toggle from '../../common/Toggle'
import Edit from '../../common/Edit'
import Delete from '../../common/Delete'
import Author from '../../common/Author'
import RenderContent from '../../common/RenderContent'


const ProblemDetails = ({ problem, handlePut, handleDelete, changeVisibility, actionable }) => {
    return(
        <div style={styles.container}>
            { actionable && <div style={styles.actionsWrapper}>
                <Toggle on={problem.visible} action={() => changeVisibility(problem.id)}/>

                <Edit action={() => handlePut(problem)}/>

                <Delete action={() => handleDelete(problem.id)}/>
            </div> }


            <Author author={problem.createdBy} date={problem.createdDate} action='posted'/>
            <Author author={problem.updatedBy} date={problem.updatedDate} action='edited'/>


            <p style={styles.title}>{problem.title}</p>


            <p style={styles.source}><b>Manba: </b>{problem.source}</p>


            <div style={styles.content}>
                <RenderContent content={problem.content} />
            </div>


            <div style={{flexGrow: 1}}/>


            <div style={styles.statistics}>
                <Statistics like={problem.likeCount} save={problem.saveCount} see={problem.seenCount} share={problem.shareCount} />

                { actionable && <Link 
                    to={`${problem.id}/solutions`}
                    state={{problem: problem}}
                    style={styles.navigate}
                >
                    <b style={styles.count}>Yechim [{problem.solutionCount}]</b>
                </Link> }
            </div>
        </div>
    )
}


const areEqual = (prevProps, nextProps) => {
    return  prevProps.problem.id === nextProps.problem.id && 
            prevProps.problem.visible === nextProps.problem.visible && 
            prevProps.actionable === nextProps.actionable
}


export default memo(ProblemDetails, areEqual)


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 400, 
        backgroundColor: 'rgb(233, 250, 240)', 
        border: '1px solid rgb(19, 141, 117)', 
        borderRadius: 20, 
        padding: 10, 
        margin: 7
    },
    actionsWrapper: {
        display: 'flex', 
        alignItems: 'center', 
        height: 35, 
        justifyContent: 'flex-end'
    },
    title: {
        fontSize: 20, 
        fontWeight: '700', 
        color: 'rgb(46, 204, 113)', 
        margin: '4px 0'
    },
    source: {
        fontSize: 18, 
        margin: '3px 0'
    },
    content: {
        margin: '7px 0'
    },
    statistics: {
        display: 'flex', 
        alignItems: 'center', 
        height: 35,
        marginBottom: 2, 
        justifyContent: 'flex-end'
    },
    navigate: {
        textDecoration: 'none', 
        display: 'flex', 
        alignItems: 'center',
        margin: '0 3px',
        padding: '3px 10px',
        borderRadius: 10,
        backgroundColor: 'rgb(255, 248, 235)',
        border: '1px solid rgb(160, 64, 0)'
    },
    count: {
        fontSize: 16,
        color: 'rgb(180, 84, 20)'
    }
}