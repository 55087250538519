import React, { useState, useRef } from 'react'
import { toPng } from 'html-to-image'
import { toast } from 'react-toastify'

import useAxios from '../../../auth/useAxios'
import Apply from '../../common/Apply'
import Close from '../../common/Close'
import Tags from '../../common/Tags'
import TextInput from '../../common/TextInput'
import ShareTemplate from '../../common/ShareTemplate'


export default function ProblemWrite({ problem, subject, type, cancel }) {
    const api = useAxios('protected')
    const componentRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(problem)


    const validate = (problem) => {
        if (problem.tagsId.length === 0) {
            throw new Error('Kamida bitta teg tanlang')
        } else if (!problem.title || problem.title.length < 10) {
            throw new Error("Sarlavha kamida 10 ta belgidan iborat bo'lishi kerak")
        } else if (!problem.source || problem.source.length < 3) {
            throw new Error("Manba kamida 3 ta belgidan iborat bo'lishi kerak")
        } else  if (!problem.content || problem.content.length < 30) {
            throw new Error("Savol kamida 30 ta belgidan iborat bo'lishi kerak")
        }
    }

    const getShareUrl = async () => {
        const node = componentRef.current

        try {
            const dataUrl = await toPng(node, { quality: 1, pixelRatio: 2 })
            const response = await fetch(dataUrl)
            const blob = await response.blob()
        
            const formData = new FormData()
            formData.append('file', blob, 'problem-content.png')
        
            const res = await api.post('/files', formData)
        
            return res.data
        } catch (error) {
            toast.error('Rasm saqlashda muammo vujudga keldi: ' + error.data)
            throw error
        }
    }

    const postProblem = async () => {
        try {
            setLoading(true)

            validate(current)

            const url = await getShareUrl()

            await api.post('/problems', {
                ...current,
                shareUrl: url
            })
    
            cancel()
            toast.success('Post saqlandi.')
        } catch (error) {
            if (error.data) {
                toast.error('Post saqlashda muammo vujudga keldi: ' + error.data)
            } else {
                toast.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }

    const putProblem = async () => {
        try {
            setLoading(true)

            validate(current)

            let url = null
            if (current.content !== problem.content) {
                url = await getShareUrl()
            } else {
                url = problem.shareUrl
            }

            await api.put(`/problems/${problem.id}`, {
                ...current,
                shareUrl: url
            })
    
            cancel()
            toast.success("Post o'zgartirildi.")
        } catch (error) {
            if (error.data) {
                toast.error("Post o'zgartirishda muammo vujudga keldi: " + error.data)
            } else {
                toast.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }

    return(
        <div style={styles.container}>
            <div style={styles.actionsWrapper}>
                <p style={styles.modalName}>Post ma'lumotlari</p>

                { type === 'POST' && <Apply loading={loading} name="QO'SHISH" apply={postProblem}/> }

                { type === 'PUT' && <Apply loading={loading} name="O'ZGARTIRISH" apply={putProblem} color1='rgb(187, 143, 206)' color2='rgb(210, 180, 222)'/> }

                <Close loading={loading} close={cancel}/>
            </div>


            <p style={styles.title}>Teglar:</p>
            <Tags tags={subject.tags} tagsId={current.tagsId} setter={(ids => setCurrent(prev => ({...prev, tagsId: ids })))} />
            

            <p style={styles.title}>Sarlavha:</p>
            <TextInput 
                value={current.title || ''}
                setValue={(value) => setCurrent(prev => ({
                    ...prev,
                    title: value
                }))}
            />
            

            <p style={styles.title}>Manba:</p>
            <TextInput 
                value={current.source || ''}
                setValue={(value) => setCurrent(prev => ({
                    ...prev,
                    source: value
                }))}
            />


            <p style={styles.title}>Savol:</p>
            <div style={styles.contentWrapper}>
                <TextInput 
                    value={current.content || ''}
                    setValue={(value) => setCurrent(prev => ({
                        ...prev,
                        content: value
                    }))}
                    ratio={5}
                />


                <div style={styles.shareWrapper}>
                    <div ref={componentRef}>
                        <ShareTemplate content={current.content}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 20,
        borderRadius: 10, 
        backgroundColor: 'rgb(249, 253, 255)', 
        boxShadow: '0 2px 7px rgb(125, 125, 125)'
    },
    actionsWrapper: {
        display: 'flex', 
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 50,
        marginTop: 7,
    },
    modalName: {
        flex: 1,
        fontSize: 25,
        fontWeight: '700'
    },
    title: {
        fontSize: 18, 
        fontWeight: '700',
        color: 'rgb(40, 40, 40)',
        marginTop: 10,
    },
    contentWrapper: {
        display: 'flex', 
        alignItems: 'flex-start',
    },
    shareWrapper: {
        backgroundColor: 'rgb(255, 255, 255)', 
        marginLeft: 10, 
        border: '1px solid rgb(255, 0, 0)'
    }
}
