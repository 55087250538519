import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'

import baseUrl from './baseUrl'


export const AuthContext = createContext()


export const AuthProvider = ({ children }) => {
  const [routes, setRoutes] = useState(null)


  useEffect(() => {
    const getNewToken = async () => {
      await updateToken()
    }

    getNewToken()
  }, [])

  
  const login = async (username, password) => {
    try {
      const response = await axios.post(`${baseUrl}/public/users/log`, {
        username: username,
        password: password
      })
      

      sessionStorage.setItem('refresh_token', response?.data.refresh_token)
      sessionStorage.setItem('routes', JSON.stringify(response?.data.routes))
      localStorage.setItem('access_token', response?.data.access_token)
      
      setRoutes(response?.data.routes)
    } catch (err) {
      throw err
    }
  }

  const logout = async () => {
    setRoutes(null)
    

    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('routes')
    localStorage.removeItem('refresh_token')
  }

  const updateToken = async () => {
    const refreshToken = sessionStorage.getItem('refresh_token')
    const routes = sessionStorage.getItem('routes')


    if (refreshToken) {
      try {
        const response = await axios({
          method: 'POST',
          url: `${baseUrl}/public/users/refresh-token`,
          headers: {
            'Authorization': `Bearer ${refreshToken}`,
          }
        })

        
        localStorage.setItem('access_token', response?.data.access_token)
        setRoutes(JSON.parse(routes))
      } catch (err) {
        setRoutes(null)
      }
    } else {
      setRoutes(null)
    }
  }


  return (
    <AuthContext.Provider value={{ routes, login, logout, updateToken }}>
      {children}
    </AuthContext.Provider>
  )
}