import React from 'react'

import Close from '../../common/Close'
import SpecialTestDetails from './SpecialTestDetails'


export default function SpecialBlockSee({ tests, handleSee, handleSelect, cancel }) {
    return(
        <div style={styles.container}>
            <div style={styles.actionsWrapper}>
                <p style={styles.modalName}>Tanlangan maxsus testlar [{tests.length}]</p>
                
                <Close loading={false} close={cancel}/>
            </div>


            <div style={styles.content}>
                { tests.map(test => (
                    <SpecialTestDetails 
                        key={test.id} 
                        test={test} 
                        handleSee={handleSee}
                        handleSelect={handleSelect} 
                        selectable={true} 
                        selected={true}
                    />
                )) }
            </div>
        </div>
    )
}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 20,
        borderRadius: 10,
        backgroundColor: 'rgb(249, 253, 255)', 
        boxShadow: '0 2px 7px rgb(125, 125, 125)'
    },
    actionsWrapper: {
        display: 'flex', 
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 50,
        marginTop: 7,
    },
    modalName: {
        flex: 1,
        fontSize: 25,
        fontWeight: '700',
        marginLeft: 15,
    },
    content: {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        backgroundColor: 'rgb(255, 255, 255)', 
        padding: 10
    }
}