import React, { useRef, useEffect } from 'react';


export default function  TextInput({ value, setValue, ratio = 1}) {
  const textareaRef = useRef(null);

  const handleInput = (e) => {
    setValue(e.target.value)
  }

  const adjustHeight = (textarea) => {
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight(textareaRef.current)
    }
  }, [value])


  return (
    <textarea
      ref={textareaRef}
      value={value}
      onInput={handleInput}
      style={{ padding: 7, fontSize: 20, width: '100%', minHeight: 32 * ratio, resize: 'none', overflow: 'hidden', boxSizing: 'border-box'}}
    />
  )
}
