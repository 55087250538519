import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import flag from '../../assets/flag.png'
import logo from '../../assets/logo.png'


export default function DeleteVerify() {
    const navigate = useNavigate()
    const location = useLocation()
    const api = useAxios('public')
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const phone = location?.state?.username || '901234567';


    const handlePress = async () => {
        try {
            setLoading(true)

            const response = await api.delete('/users/delete/verify', {
                params: {
                    username: '998' + phone,
                    code: code
                }
            })

            if (response.data) {
                toast.success('Akkauntizgiz tizimdan muvaffaqiyatli o\'chirildi')
                navigate('/')
            } else {
                toast.info('Jarayonni bajarishda xatolik yuzaga keldi.')
            }
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Serverda xatolik yuz berdi. Iltimos keyinroq urinib ko\'ring'
            toast.error(message)
        } finally {
            setLoading(false)
        }
    }


    return (
        <div className='delete'>
            <div className='form-container'>
                <img src={logo} alt='' className='form-logo'/>

                <p className='form-text'>SMS tasdiqlash</p>

                <p className='form-description'>Jarayonni muvaffaqiyatli yakunlash uchun tasdiqlash kodini kiriting.</p>

                <p className='form-label'>Telefon raqam <span style={{color: 'red'}}>*</span></p>
                <div className='form-input' style={{border: '1px solid rgb(190, 190, 190)'}}>
                    <img src={flag} alt='' style={{height: 22, width: 33, marginRight: 15, marginLeft: 15, paddingBottom: 1}}/>
                    <p style={{fontSize: 18, fontWeight: '700', width: 50, borderRightColor: 'rgb(190, 190, 190)', borderRightWidth: 1, color: 'rgb(117, 117, 117)', flex: 1}}>+998 {phone}</p>
                </div>

                <p className='form-label'>Kod <span style={{color: 'red'}}>*</span></p>
                <div className='form-input' style={{border: '1px solid rgb(190, 190, 190)', backgroundColor: 'rgb(255, 255, 255)'}}>
                    <input
                        style={{fontSize: 18, fontWeight: '600', outline: 'none', border: 'none', height: 50, width: 340}}
                        value={code}
                        maxLength={20}
                        onChange={(event) => setCode(event.target.value)}
                        required
                    />
                </div>

                { loading ?    
                    <button
                        className='form-input'
                        style={{fontSize: 18, fontWeight: '700', backgroundColor: 'rgb(170, 170, 170)', color: 'rgb(255, 255, 255)', border: 'none', marginTop: 10}}
                    >
                        Tasdiqlash
                    </button> :
                    <button
                        className='form-input'
                        style={{fontSize: 18, fontWeight: '700', backgroundColor: 'rgb(10, 130, 216)', color: 'rgb(255, 255, 255)', border: 'none', transition: 'background-color 0.3s ease', marginTop: 10}}
                        onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgb(20, 158, 255)' }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgb(10, 130, 216)' }}
                        onClick={handlePress}
                    >
                        Tasdiqlash
                    </button>
                }
            </div>
        </div>
    )
}