import React from 'react'

import Difficulty from './Difficulty'


export default function Difficulties({ difficulties, handleChange }) {
    return(
        <div style={styles.containeer}>
            <Difficulty name='EASY' selected={difficulties.includes('EASY')} handleClick={() => handleChange('EASY')}/>

            <Difficulty name='MEDIUM' selected={difficulties.includes('MEDIUM')} handleClick={() => handleChange('MEDIUM')}/>

            <Difficulty name='HARD' selected={difficulties.includes('HARD')} handleClick={() => handleChange('HARD')}/>
        </div>
    )
}


const styles = {
    containeer: {
        display: 'flex'
    },
    difficulty: {
        height: 25, 
        fontSize: 17,
        fontWeight: '600',
        border: 'none',
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: 'rgb(25, 25, 25)',
        backgroundColor: 'rgb(46, 204, 113)',
        cursor: 'pointer',
        userSelect: 'none',
    },
    easy: 'rgb(125, 206, 160)',
    medium: 'rgb(248, 196, 113)',
    hard: 'rgb(241, 148, 138)'
}
