import React, { useState, useEffect } from 'react'
import { GrCheckbox } from "react-icons/gr"
import { GrCheckboxSelected } from "react-icons/gr"

import useAxios from '../../auth/useAxios'


export default function SubjectTagsList({ subjectId, tagsId, setSubject, addTag }) {
    
    const api = useAxios('protected')
    const [subjects, setSubjects] = useState([])

    useEffect(() => {
        getSubjects()
    }, [])

    const getSubjects = async () => {
        try {
            const response = await api.get("/subjects")

            setSubjects(response.data)
        } catch (error) {
            console.error(error)
        }
    }


    return(
        <div style={{marginBottom: 7}}>
            { subjects.map(subject => (
                <div key={subject.id}>
                    <div
                        onClick={() => setSubject(subject.id)}
                        style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: 'rgb(242, 243, 244)', border: '1px solid rgb(0, 0, 0)'}}
                    >
                        <p style={{fontSize: 19, fontWeight: '700', flex: 1}}>{subject.name}</p>
                        { subjectId === subject.id ? (
                            <GrCheckboxSelected style={{width: 22, height: 22, color: 'rgb(35, 155, 86)', marginRight: 10}}/>
                        ) : (
                            <GrCheckbox  style={{width: 22, height: 22, color: 'rgb(144, 148, 151)', marginRight: 10}}/>
                        ) }
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        { subjectId === subject.id && 
                            subject.tags.map(tag => (
                                <div 
                                    key={tag.id}
                                    style={{ backgroundColor: tagsId.includes(tag.id) ? 'rgb(52, 152, 219)' : 'rgb(234, 242, 248)', borderRadius: 15, padding: '7px 14px', margin: 7, border: '1px solid rgb(127, 179, 213)'}}
                                    onClick={() => addTag(tag.id)}
                                >
                                    <p style={{fontSize: 17, fontWeight: '500'}}>{tag.name}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}
