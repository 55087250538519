import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import useAxios from '../../auth/useAxios'
import ActionModal from '../common/ActionModal'
import Apply from '../common/Apply'
import Reload from '../common/Reload'
import ComponentLoading from '../common/ComponentLoading'
import ProblemDetails from './components/ProblemDetails'
import SolutionDetails from './components/SolutionDetails'
import SolutionWrite from './components/SolutionWrite'


export default function Solutions() {
    const api = useAxios('protected')
    const location = useLocation()
    const { problem } = location.state || {}
    const [component, setComponent] = useState(null) // stores current component for POST or PUT action
    const [solutions, setSolutions] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getSolutions()
    }, [])

    const getSolutions = async () => {
        try {
            setLoading(true)

            const response = await api.get(`/problems/${problem.id}/solutions`)

            setSolutions(response.data)
        } catch (error) {
            toast.error('Failed to get solutions: ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const handlePost = () => {
        const current = {
            content: ''
        }

        setComponent(<SolutionWrite solution={current} problemId={problem.id} type ='POST' cancel={() => setComponent(null)} />)
    }

    const handlePut = (solution) => {
        const current = {
            id: solution.id,
            content: solution.content
        }

        setComponent(<SolutionWrite solution={current} problemId={problem.id} type ='PUT' cancel={() => setComponent(null)} />)
    }

    const handleDelete = async (solutionId) => {
        const res = window.confirm('Want to delete this solution')

        if (!res) {
            return
        }

        try {
            setLoading(true)

            await api.delete(`/problems/${problem.id}/solutions/${solutionId}`)

            // remove deleted solution from solutions list
            setSolutions(prev => prev.filter(solution => solution.id !== solutionId))
            
            toast.success('Solution has been deleted')
        } catch (error) {
            toast.error('Failed to DELETE(solution): ' + error.message)
        } finally {
            setLoading(false)
        }
    }

    const changeVisibility = async (solutionId) => {
        try {
            await api.put(`/problems/${problem.id}/solutions/${solutionId}/visibility`)
            
            setSolutions(prev => prev.map(s => {
                if (s.id === solutionId) {
                    return {
                        ...s,
                        visible: !s.visible
                    }
                } else {
                    return s
                }
            }))

            toast.success('Solution\' visibility has been changed successfully')
        } catch (error) {
            toast.error('Failed to make solution visible: ' + error.message)
        }
    }


    if (loading) {
        return <ComponentLoading />
    }


    return(
        <div style={styles.container}>
            { component && <ActionModal children={component} /> }

            <div style={styles.actionsWrapper}>
                <Apply loading={loading} name="Post qo'shish" apply={handlePost} />

                <Reload loading={loading} action={getSolutions}/>
            </div>

            <div style={styles.content}>
                <ProblemDetails problem={problem} />

                { solutions.map(solution => (
                    <SolutionDetails 
                        key={solution.id} 
                        solution={solution} 
                        problemId={problem.id}
                        handlePut={handlePut} 
                        handleDelete={handleDelete}
                        changeVisibility={changeVisibility}
                    />
                )) }
            </div>
        </div>
    )
}


const styles = {
    container: {
        // correct right overflow
        marginRight: 40
    },
    actionsWrapper: {
        display: 'flex', 
        flexDirection: 'row', 
        height: 45, 
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 20px', 
        backgroundColor: 'rgb(234, 237, 237)',
        position: 'sticky',
        top: 0,
        zIndex: 200,
    },
    content: {
        display: 'flex', 
        flexDirection: 'row', 
        flexWrap: 'wrap', 
        backgroundColor: 'rgb(255, 255, 255)', 
        padding: 10
    }
}